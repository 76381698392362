<template>
  <div>
    <component
      :is="currentLayout"
      :info="$store.state.user.user"
      :isInfoLoading="isInfoLoading"
    >
      <router-view />
      <transition name="popup-anim"> <PopupController /> </transition>
    </component>
  </div>
  <PopupController />
  <div class="alert-body">
    <transition-group name="tray-anim">
      <Alert
        v-for="item in $store.state.trayItems"
        :key="item.id"
        :label="item"
      />
    </transition-group>
  </div>
</template>

<script>
import Layout from "@/components/Layout";
import LayoutStart from "@/components/LayoutStart";
import LayoutNoSidebar from "@/components/LayoutNoSidebar";
import PopupController from "@/components/Popups/PopupController";
import Alert from "@/components/Blocks/Alert";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";
import store from "@/store";

export default {
  name: "App",
  components: { Alert, Layout, PopupController, LayoutStart, LayoutNoSidebar },
  data() {
    return {
      isInfoLoading: false,
    };
  },
  watch: {
    frontVersion(value) {
      this.$store.commit("setServerAvatar", "");
      for (let key in this.$store.state.storage) {
        if (typeof this.$store.state.storage[key] === "object") {
          this.$store.state.storage[key] = {};
        } else if (this.$store.state.storage[key].isArray()) {
          this.$store.state.storage[key].length = 0;
        }
      }
      this.$store.commit("user/killUserFull");
      this.$store.commit("setStorageInfoLoading", true);

      if (this.isAuthenticated) {
        this.$store.dispatch("user/getUserInfo");
      }
    },
    $route() {
      if (this.isTelegram) {
        this.$store.commit("user/setIsTelegramInfo", true);
      }
      if (this.isAuthenticated) {
        if (!this.isTelegram) {
          if (
            this.$route.name !== "ProfileData" &&
            this.$route.name !== "PersonalData"
          ) {
            if (this.setIsProfileMustEdit || this.setIsPassportMustEdit) {
              this.$store.commit("setCurrentPopup", {
                name: "PopupMustEdit",
                isShown: true,
              });
            }
          }
        }
      }
    },
    isAuthenticated(value) {
      if (!value) this.clearLocalStorage();
    },
  },
  methods: {
    randomBackend() {
      let foo = Math.random() * 100;
      if (foo < 30) {
        localStorage.setItem("isProxyServer", "false");
      } else {
        localStorage.setItem("isProxyServer", "true");
      }
    },
    clearLocalStorage() {
      if (!this.isAuthenticated) {
        this.$store.commit("user/killUserFull");
        this.$store.dispatch("logout");
      }
    },
  },
  computed: {
    currentLayout() {
      if (!this.isAuthenticated) {
        return "LayoutStart";
      } else if (this.$route.meta.withoutSidebar && this.isAuthenticated) {
        return "LayoutNoSidebar";
      } else return "Layout";
    },
    ...mapState(["isCookieAgree", "frontVersion", "frontVersionChanged"]),
    ...mapState("user", [
      "isAuthenticated",
      "isTelegram",
      "setIsProfileMustEdit",
      "setIsPassportMustEdit",
    ]),
  },
  async beforeMount() {
    if (localStorage.getItem("isProxyServer") === null) {
      this.randomBackend();
    }
    if (this.isAuthenticated) {
      this.isInfoLoading = true;
      await this.$store.dispatch("user/getUserInfo").finally(() => {
        this.isInfoLoading = false;
      });
      if (!this.isTelegram) {
        if (
          this.$route.name !== "ProfileData" &&
          this.$route.name !== "PersonalData"
        ) {
          if (this.setIsProfileMustEdit || this.setIsPassportMustEdit) {
            this.$store.commit("setCurrentPopup", {
              name: "PopupMustEdit",
              isShown: true,
            });
          }
        }
      }
    }
  },
  mounted() {
    this.clearLocalStorage();
  },
};
</script>

<style lang="scss">
@import "~@/scss/style.scss";
</style>
