export default [
  {
    link: { path: "/profile" },
    text: "Мой профиль",
    class: "btn--small",
  },
  {
    link: { name: "Events" },
    text: "Мероприятия",
  },
  {
    link: { name: "Applications" },
    text: "Мои заявки",
    meta: "disabled",
  },
  {
    link: { name: "Portfolio" },
    text: "Мое портфолио",
    meta: "disabled",
  },
  {
    link: { name: "Performance" },
    text: "Успеваемость",
    meta: "disabled",
  },
  {
    link: { name: "SocialActivity" },
    text: "Деятельность",
    meta: "disabled",
  },
  // {
  //   link: { name: "Appeals" },
  //   text: "Мои обращения",
  //   meta: "disabled",
  // },
  {
    link: { name: "Contacts" },
    text: "Контакты",
  },
  {
    link: { name: "MembershipPerson" },
    text: "Членство",
    meta: "disabled",
  },
];
