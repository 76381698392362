import backend from "@/api/rest";
import router from "@/router";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export async function getCaptcha() {
  let result = {};

  await backend
    .get("/captcha", { responseType: "arraybuffer" })
    .then((response) => {
      result = {
        imgBase64: Buffer.from(response.data, "binary").toString("base64"),
        secret: response.headers["secret-code"],
      };
    });
  return result;
}

export async function getUserInfo({ commit, dispatch, state, getters }) {
  commit("setIsInfoLoading", true);
  await createRequest(requestConfigs.GETInfo).then((result) => {
    commit("setUser", result.data);
    commit("setIsInfoLoading", false);
    commit("setIsTelegram", state.user?.telegram_link);
    commit("setIsTelegramInfo", !!state.isTelegram);
    if (state.disabledFields["isEducationFilled"]) {
      commit("setEducationPopupShown");
    }
  });
  // profile
  dispatch("hasProfileData", [
    ["first_name", "last_name", "birthday"],
    "isProfileFilled",
  ]);
  dispatch("hasProfileData", [
    ["info_educational_establishment", "info_faculty", "info_level_education"],
    "isEducationFilled",
  ]);
  // projects
  dispatch("hasProfileData", [
    ["about", "first_name", "last_name"],
    "isProjectProfileFilled",
  ]);
  dispatch("hasProfileData", [
    ["first_name", "last_name", "info_educational_establishment"],
    "isSYPersonalFilled",
  ]);
  dispatch("hasProfileData", [
    ["first_name", "last_name", "info_educational_establishment"],
    "isAYPersonalFilled",
  ]);
  dispatch("hasProfileData", [
    ["first_name", "last_name", "href_vk_publication_project"],
    "isHSProfileFilled",
  ]);
  dispatch("hasProfileData", [
    ["first_name", "last_name", "education_city_id"],
    "isLFFirstShiftProfileFilled",
  ]);
  dispatch("hasProfileData", [
    ["first_name", "last_name", "education_city_id"],
    "isLFSecondShiftProfileFilled",
  ]);
  dispatch("hasProfileData", [
    ["first_name", "last_name", "education_city_id"],
    "isLFThirdShiftProfileFilled",
  ]);
  commit(
    "setHSPersonalDataFilled",
    Object.values(state.user?.passport)?.length > 0 &&
      Boolean(
        state.user?.passport?.document_files?.document_passport_6_7_page?.full
      ) &&
      Boolean(
        state.user?.passport?.document_files?.document_passport_main_page?.full
      )
  );
  // membership
  commit("setCurrentMembershipStatus", state.user?.info_liga_status?.id);
  //telegram

  //spo
  commit(
    "setIsSPO",
    state.user?.info_educational_establishment?.type?.key === 2
  );
  //из Республики Татарстан?
  commit("setIsRT", state.user?.education_region_id === 5246);
  //citizen Russia
  commit("setDisabledData", ["isCitizenRussia", state.user?.is_citizen_russia]);
  //is student
  commit("setDisabledData", ["isNotStudent", state.user?.is_not_student]);
  //edit fields
  if (getters.infoFix) {
    let copyInfoFix = JSON.parse(JSON.stringify(getters.infoFix)) || {};
    delete copyInfoFix.passport;
    let mustEdit = Object.values(copyInfoFix).some((item) => {
      return item;
    });
    commit("setIsProfileMustEdit", mustEdit);
  }

  if (getters.infoFixPassport) {
    let copyInfoFixPassport =
      JSON.parse(JSON.stringify(getters.infoFixPassport)) || {};

    let mustEditPassport = Object.values(copyInfoFixPassport).some((item) => {
      return item;
    });

    commit("setIsPassportMustEdit", mustEditPassport);
  }
  //activity
  commit("setSocialActivity", state.user?.info_social_activity?.data);
  commit("setLaborActivity", state.user?.info_labor_activity?.data);
  commit("setDisabledData", [
    "isSocialActivityFilled",
    state.socialActivity.length > 0,
  ]);
  commit("setDisabledData", [
    "isLaborActivityFilled",
    state.laborActivity.length > 0,
  ]);
}

export async function userAuth({ commit, dispatch }, { token }) {
  commit("setBackendToken", token);
  await dispatch("getUserInfo").then(() => {
    commit("setIsAuthenticated", true);
    router.push({ name: "Events" });
  });
}
export const hasProfileData = (
  { commit, state },
  [keyArray, stateFieldName]
) => {
  let sortedArr = [];
  if (keyArray) {
    sortedArr = Object.values(keyArray)
      ?.map((item) => {
        return Boolean(state.user[item]);
      })
      .every((item) => {
        return item === true;
      });
  }

  return commit("setDisabledData", [stateFieldName, sortedArr]);
};

export const hasProjectEducationData = (
  { commit, state },
  [keyArray, stateFieldName]
) => {
  let sortedArr;
  if (keyArray) {
    sortedArr = Object.values(keyArray)
      ?.map((item) => {
        return Boolean(state.user?.education?.document_files[item]?.full);
      })
      .every((item) => {
        return item === true;
      });
  }
  return commit("setDisabledData", [stateFieldName, sortedArr]);
};

export const checkProjectValidation = (
  { commit, state, rootGetters },
  [keyArray, stateFieldName, type, keyFields]
) => {
  let sortedArr = true;
  let checkFields = true;
  let finishValue;
  if (keyArray && keyArray.length > 0) {
    sortedArr = Object.values(keyArray)
      ?.map((item) => {
        if (rootGetters.socialDocs.includes(item)) {
          return Boolean(
            state.user["social_documents"]?.document_files[item]?.full
          );
        } else if (type === "passport") {
          return Boolean(state.user["passport"]?.document_files[item]?.full);
        } else
          return Boolean(state.user["education"]?.document_files[item]?.full);
      })
      .every((item) => {
        return item === true;
      });
  }
  if (keyFields && keyFields.length > 0) {
    checkFields = keyFields
      .map((i) => {
        return Boolean(state.user[type][i]);
      })
      .every((i) => {
        return i === true;
      });

    sortedArr = sortedArr && checkFields;
  }
  checkFields
    ? (finishValue = sortedArr && checkFields)
    : (finishValue = sortedArr);
  return commit("setDisabledData", [stateFieldName, finishValue]);
};

export function getNeedValidationDocuments({ state }) {
  return state.user.info_validation_documents.data.filter(
    (doc) => doc.status === 40
  ).length;
}
