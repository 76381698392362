import uniqueString from "unique-string";
import moment from "moment";
export function setUiLanguage(state, data) {
  state.uiLanguage = data;
}
export function setCurrentPopup(state, data) {
  Object.assign(state.currentPopup, data);
}
export function pushToTray(state, data) {
  state.trayItems.unshift({
    text: data.text,
    type: ["success", "error", "info"].filter((type) => type === data.type),
    id: uniqueString(),
  });
  if (state.trayItems.length > 3) state.trayItems.pop();
}
export function removeFromTray(state, itemId) {
  state.trayItems = state.trayItems.filter((item) => item.id !== itemId);
}
export function setDontSavedCrop(state, data) {
  state.dontSavedCrop = data;
}
export function setStorageData(state, [data, stateName]) {
  state.storage[stateName] = data;
}
export function setDocument(state, [data, stateName]) {
  state.docs[stateName] = data;
}
export function setIsMainInfoLoading(state, data) {
  state.isMainInfoLoading = data;
}
export function setFrontVersion(state, data) {
  state.frontVersion = data.front_version;
}
export function setFrontVersionChanged(state, data) {
  state.frontVersionChanged = data;
}
export function setPortfolioList(state, data) {
  state.portfolioList = data.sort((a, b) => {
    if (a.year === b.year) {
      return moment(a.created_at) < moment(b.created_at) ? 1 : -1;
    } else {
      return a.year < b.year ? 1 : -1;
    }
  });
}
export function setNewPortfolioList(state, data) {
  state.newPortfolioList = data;
}
export function setPortfolioListLoaded(state, data) {
  state.portfolioListLoaded = data;
}
export function setPortfolioListLength(state, data) {
  state.portfolioListLength = data;
}
export function setStatementsList(state, data) {
  state.statementsList = data;
}
export function setStatementId(state, data) {
  state.statementId = data;
}
export function setCurrentCategoryData(state, data) {
  state.currentCategoryData = data;
}
export function setNominationValue(state, [key, data]) {
  state[key] = data;
}
export function setPortfolioDoc(state, data) {
  state.portfolioDoc = data;
}
export function setPortfolioDocId(state, data) {
  state.portfolioDocId = data;
}
export function setHSDoc(state, [key, data]) {
  state.HSDocsList[key].doc = data;
}
export function setHSDocId(state, [key, data]) {
  state.HSDocsList[key].id = data;
}
export function setHSDocUrl(state, [key, data]) {
  state.HSDocsList[key].url = data;
}
export function clearHSDocs(state) {
  state.HSDocsList = {
    portfolio: { doc: "", id: "", url: false },
    anketa_client: { doc: "", id: "", url: false },
    financial_personal_account_indicating_residential_total_area: {
      doc: "",
      id: "",
      url: false,
    },
    certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family:
      { doc: "", id: "", url: false },
    // certificate_main_need_to_improve_living_conditions: {
    //   doc: "",
    //   id: "",
    //   url: false,
    // },
  };
}
export function setStudentYearCollectiveData(state, data) {
  state.studentYearCollectiveData = data;
}
export function setAchievementYearCollectiveData(state, data) {
  state.achievementYearCollectiveData = data;
}
export function setCurrentCategoryId(state, data) {
  state.currentCategoryId = data;
}
export function setCurrentCategoryProject(state, data) {
  state.currentCategoryProject = data;
}
export function setCurrentCategoryVersion(state, data) {
  state.currentCategoryVersion = data;
}
export function setDeleteBtnLoading(state, data) {
  state.isDeleteBtnLoading = data;
}
export function setStorageInfoLoading(state, data) {
  state.isStorageInfoLoading = data;
}
export function setAchievementItem(state, data) {
  state.achievementItem = data;
}
export function setAchievementIList(state, data) {
  state.achievementsList = data;
}
export function setAchievementItemForPush(state, data) {
  state.achievementsItemForPush = data;
}
export function setCurrentAchievementId(state, data) {
  state.currentAchievementId = data;
}
export function setIsOnAchievementsPage(state, data) {
  state.isOnAchievementsPage = data;
}
export function isCookieAgreeTrue(state) {
  state.isCookieAgree = true;
}
export function setServerAvatar(state, data) {
  state.serverAvatar = data;
}
export function setProxyServer(state, data) {
  state.isProxyServer = data;
}
export function setAppealsList(state, data) {
  state.appealsList = data;
}
export function setShiftStatementData(state, data) {
  Object.assign(state[data.key], data.form);
}
export function clearShiftStatementData(state, key) {
  state[key] = {};
}
