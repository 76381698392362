<template>
  <div class="layout-no-sidebar">
    <Header />
    <!--    <div class="layout-tg" v-if="isCore && isProxy">-->
    <!--      <div class="layout-tg__content">-->
    <!--        <span>-->
    <!--          Высокая нагрузка на сайт, воспользуйтесь-->
    <!--          <a href="https://lk-pr.ligastudentov.com"> данной ссылкой </a>-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </div>-->
    <main class="layout-no-sidebar__main">
      <div class="layout-no-sidebar__wrapper">
        <slot></slot>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/LayoutSection/Header";
import Footer from "@/components/LayoutSection/Footer.vue";
export default {
  name: "LayoutNoSidebar",
  components: { Footer, Header },
  computed: {
    isCore() {
      return (
        process.env.VUE_APP_BACKEND_URL ===
        "https://core.ligastudentov.testsavatneev.ru"
      );
    },
    isProxy() {
      return localStorage.getItem("isProxyServer") === "true";
    },
  },
};
</script>

<style lang="scss">
.layout-no-sidebar {
  min-height: 100vh;
  color: var(--text--color);
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-weight: 400;
  &__main {
    max-width: 1560px;
    margin: 0 auto;
    padding: 80px 20px;
    box-sizing: content-box;
    @include adaptive(tablet-big) {
      padding-top: 30px;
    }
    @include adaptive(phone) {
      padding-top: 20px;
    }
  }
}
</style>
