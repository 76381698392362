import moment from "moment";

export const projectSettings = (state) => {
  return state.storage.projectsList.map((item) => {
    return {
      id: item.id,
      key: item.key,
      settings: item.settings,
      title: item.title,
    };
  });
};
export const projectsList = (state) => {
  return state.storage.projectsList;
};
export const socialActiveDocsList = (state) => {
  return state.statementsList
    .find((item) => {
      return (
        item.info_category.id === 2 &&
        item.info_project.key === "transport_grant"
      );
    })
    ?.info_documents?.data.map((item) => {
      let parsedItem = {
        created_at: item?.info_document?.created_at,
        id: item?.info_document?.id,
        title: item?.info_document?.title,
        url_original: item?.info_document?.url_original,
        url_thumb: item?.info_document?.url_thumb,
        year: item?.info_document?.year,
        project_tree_id: item?.info_project_tree?.id,
        project_tree_title: item?.info_project_tree?.title,
        project_tree_points: item?.info_project_tree?.points,
      };
      return parsedItem;
    });
};
export const hashMedia = (state) => {
  return state.storage.systemInfo.is_hash_media;
};
export const enableEmail = (state) => {
  return state.storage.systemInfo.is_enable_email;
};
export const filteredAppealsList = (state) => {
  return state.appealsList.sort((a, b) => {
    return moment(b.created_at) - moment(a.created_at);
  });
};

export const russianPassportDocs = (state) => {
  return state.storage.systemInfo.document_settings.passport[
    "is_citizen_russia"
  ];
};
export const foreignPassportDocs = (state) => {
  return state.storage.systemInfo.document_settings.passport[
    "is_citizen_no_russia"
  ];
};
export const educationDocs = (state) => {
  return state.storage.systemInfo.document_settings.education;
};
export const allTypeDocs = (state) => {
  return state.storage.systemInfo.document_settings.all_type;
};
export const socialDocs = (state) => {
  return state.storage.systemInfo.document_settings.social;
};
export const isPDFDocs = (state) => {
  return state.storage.systemInfo.document_settings.is_pdf;
};
export const isAllDocsLabels = (state) => {
  return state.storage.systemInfo.list_translate_all_documents;
};
export const docsStatus = (state) => {
  return state.storage.systemInfo.status_document_check;
};
export const portfolioSize = (state) => {
  return (
    state.storage.systemInfo.support_settings.max_file_sizes.portfolio ?? 75
  );
};
export const achievementSize = (state) => {
  return (
    state.storage.systemInfo.support_settings.max_file_sizes.achievements ?? 2
  );
};
export const regionsList = (state) => {
  let sortedArr = JSON.parse(
    JSON.stringify(Object.values(state.storage.systemInfo?.educational_regions))
  );
  sortedArr.sort((a, b) => {
    let fieldA = a.title.toLowerCase(),
      fieldB = b.title.toLowerCase();

    if (fieldA < fieldB) return -1;

    if (fieldA > fieldB) return 1;

    return 0;
  });

  return sortedArr;
};
export const citiesList = (state) => {
  let sortedArr = JSON.parse(
    JSON.stringify(Object.values(state.storage.systemInfo?.educational_cities))
  );
  sortedArr.sort((a, b) => {
    let fieldA = a.title.toLowerCase(),
      fieldB = b.title.toLowerCase();

    if (fieldA < fieldB) return -1;

    if (fieldA > fieldB) return 1;

    return 0;
  });

  return sortedArr;
};
export const directions = (state) => {
  return state.storage.systemInfo.directions;
};
export const teams = (state) => {
  return state.storage.systemInfo.teams;
};
export const communityRepresents = (state) => {
  return state.storage.systemInfo.community_represents.sort((a, b) => {
    return a.id - b.id;
  });
};
export const positionsOrganisations = (state) => {
  return state.storage.systemInfo.position_organizations;
};
export const foodTypes = (state) => {
  return state.storage.systemInfo.food_types;
};
export const clothingSizes = (state) => {
  return state.storage.systemInfo.clothing_sizes;
};
export const hearAboutInfo = (state) => {
  return state.storage.systemInfo.hear_about_forum;
};
