import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import state from "@/store/state";
import * as mutations from "@/store/mutations";
import * as getters from "@/store/getters";
import * as actions from "@/store/actions";

import user from "./user";

export default createStore({
  state,
  mutations,
  getters,
  actions,
  modules: {
    user,
  },
  plugins: [
    createPersistedState({
      paths: [
        "user.backendToken",
        "user.isAuthenticated",
        "user.disabledFields.isCollectiveDataFilled",
        "user.disabledFields.isAYCollectiveDataFilled",
        "user.disabledFields.isCitizenRussia",
        "user.disabledFields.isNotStudent",
        "storage",
        // "serverAvatar",
        "frontVersion",
        "user.isTelegram",
        "statementId",
        "currentCategoryData",
        "individualNominationValue",
        "collectiveNominationValue",
        "granprixNominationValue",
        "AYindividualNominationValue",
        "AYcollectiveNominationValue",
        "AYgranprixNominationValue",
        "portfolioDocId",
        "studentYearCollectiveData",
        "achievementYearCollectiveData",
        "currentCategoryProject",
        "currentCategoryVersion",
        "currentCategoryId",
        // "portfolioList",
        // "portfolioListLength",
        "portfolioListLoaded",
        "statementsList",
      ],
    }),
  ],
});
