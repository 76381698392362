<template>
  <div class="layout">
    <Header :is-info-loading="isInfoLoading" :avatar="info?.avatar" />
    <!--    <div class="layout-tg" v-if="isCore && isProxy">-->
    <!--      <div class="layout-tg__content">-->
    <!--        <span>-->
    <!--          Высокая нагрузка на сайт, воспользуйтесь-->
    <!--          <a-->
    <!--            href="https://lk-pr.ligastudentov.com"-->
    <!--            @click="$store.dispatch('logout')"-->
    <!--          >-->
    <!--            данной ссылкой-->
    <!--          </a>-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </div>-->
    <main class="main">
      <div class="main__wrapper">
        <Sidebar
          :avatar="info?.avatar"
          member="true"
          :is-info-loading="isInfoLoading"
        />
        <div
          class="slot__wrapper"
          :class="{ 'no-overflow': $route.name === 'Events' }"
        >
          <slot></slot>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/LayoutSection/Header";
import Sidebar from "@/components/LayoutSection/Sidebar";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";
import Footer from "@/components/LayoutSection/Footer.vue";
export default {
  name: "Layout",
  components: { Footer, Sidebar, Header },
  props: ["info", "isInfoLoading"],
  computed: {
    isCore() {
      return (
        process.env.VUE_APP_BACKEND_URL ===
        "https://core.ligastudentov.testsavatneev.ru"
      );
    },
    isProxy() {
      return localStorage.getItem("isProxyServer") === "true";
    },
    ...mapState("user", ["isTelegram", "isTelegramInfo", "isAuthenticated"]),
  },
  beforeMount() {
    if (!this.$store.state.storage.projectsList.length)
      createRequest(requestConfigs.GETSProjectsList).then((result) => {
        this.$store.commit("setStorageData", [result.data, "projectsList"]);
      });

    if (
      JSON.stringify(this.$store.state.storage.systemInfo) === "{}" &&
      this.isAuthenticated
    )
      createRequest(requestConfigs.GETSystemInfo).then((result) => {
        this.$store.commit("setStorageData", [result, "systemInfo"]);
      });
  },
};
</script>

<style lang="scss">
.layout {
  min-height: 100vh;
  color: var(--text--color);
  font-size: 1.6rem;
  line-height: 2.24rem;
  font-weight: 400;
  position: relative;
}
.main {
  max-width: 1560px;
  margin: 0 auto;
  padding: 80px 20px;
  box-sizing: content-box;
  &__wrapper {
    display: flex;
    gap: 40px;
  }
  .slot__wrapper {
    width: 100%;
    overflow: hidden;
    @include scrollbar();
  }
  .no-overflow {
    overflow: unset;
  }
  @include adaptive(tablet-big) {
    padding: 20px;
  }
  @include adaptive(tablet-small) {
    .slot__wrapper {
      overflow-x: clip;
      overflow-y: unset;
    }
  }
}
</style>
