import backend from "@/api/rest";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
export function validationErrors({ commit }, data) {
  data.errors
    .filter((item) => item.$message !== undefined)
    .forEach((item) => {
      return commit("pushToTray", {
        text: `${data.fieldsInfo[item.$property]}: ${item.$message}`,
        type: "error",
      });
    });
}
export function validationFormErrors({ commit }, data) {
  let filteredErrors = data.errors.map((item) => {
    Object.entries(item).forEach((i) => {
      if (i[1].length === 0) {
        delete item[i[0]];
      }
    });
    return item;
  });

  Object.values(filteredErrors).forEach((item, index) => {
    Object.values(item).forEach((i) => {
      return commit("pushToTray", {
        text: `${data.fieldsInfo[i[0].$property]}: ${i[0].$message} (Блок ${
          index + 1
        })`,
        type: "error",
      });
    });
  });
}
export function validationServerErrors({ commit }, data) {
  data.errors.forEach((item) => {
    return commit("pushToTray", {
      text: `${data.fieldsInfo[item.$property]}: ${item.$message}`,
      type: "error",
    });
  });
}
export async function getFrontInfo({ commit }) {
  await backend.get("/config/front-version").then((result) => {
    commit("setFrontVersion", result.data);
  });
}
export function getAchievementsList({ commit, state }, id) {
  let achievementsList = state.statementsList
    .find((item) => {
      return (
        item.info_category.id === 2 &&
        item.info_project.key === "transport_grant" &&
        item.id === id
      );
    })
    ?.info_documents?.data.map((item) => {
      let parsedItem = {
        created_at: item?.info_document?.created_at,
        id: item?.info_document?.id,
        title: item?.info_document?.title,
        url_original: item?.info_document?.url_original,
        url_thumb: item?.info_document?.url_thumb,
        year: item?.info_document?.year,
        project_tree_id: item?.info_project_tree?.id,
        project_tree_title: item?.info_project_tree?.title,
        project_tree_points: item?.info_project_tree?.points,
        comment_expert: item?.comment_expert,
        status: item?.status,
      };
      return parsedItem;
    });

  commit("setAchievementIList", achievementsList);
}
export function pushToAchievementsList({ state }, data) {
  state.achievementsList.push(data);
}
export function pushToPortfolioList({ state }, data) {
  state.portfolioList.unshift(data);
}
export function deleteToAchievementsList({ state }, data) {
  state.achievementsList = state.achievementsList.filter((item) => {
    return item.id !== data;
  });
}
export function deleteFromPortfolioList({ state }, data) {
  state.portfolioList = state.portfolioList.filter((item) => {
    return item.id !== data;
  });
}
export function deleteFromNewPortfolioList({ state }, data) {
  state.newPortfolioList = state.newPortfolioList.filter((item) => {
    return item.id !== data;
  });
}
export function deleteFromStatementsList({ state }, data) {
  state.statementsList = state.statementsList.filter((item) => {
    return item.id !== data;
  });
}
export async function logout({ state, commit }) {
  await commit("user/killUser", { root: true });
  commit("user/clearDisabledFields", { root: true });
  commit("setCurrentCategoryData", {});
  commit("setCurrentCategoryProject", "");
  commit("setCurrentCategoryVersion", "");
  commit("setPortfolioDocId", "");
  commit("setPortfolioList", []);
  commit("setPortfolioListLength", 0);
  commit("setPortfolioListLoaded", false);
  commit("setServerAvatar", "");
  commit("setStatementId", "");
  commit("setStorageData", [[], "projectsList"]);
  // commit("setStorageData", [{}, "systemInfo"]);
  commit("setStatementsList", []);
  commit("setStudentYearCollectiveData", {});
  commit("setAchievementYearCollectiveData", {});
  state.collectiveNominationValue = "";
  state.granprixNominationValue = "";
  state.individualNominationValue = "";
  state.AYcollectiveNominationValue = "";
  state.AYgranprixNominationValue = "";
  state.AYindividualNominationValue = "";
  state.HSDocsList = {
    portfolio: { doc: "", id: "" },
    anketa_client: { doc: "", id: "" },
    financial_personal_account_indicating_residential_total_area: {
      doc: "",
      id: "",
    },
    certificate_main_directorate_federal_registration_service_republic_tatarstan_all_family:
      { doc: "", id: "" },
    // certificate_main_need_to_improve_living_conditions: { doc: "", id: "" },
  };
  localStorage.removeItem("isProxyServer");
}
export function getAppealsList({ commit }) {
  createRequest(requestConfigs.GETMyAppeals).then((response) => {
    commit("setAppealsList", response.data);
  });
}
export function pushToAppealsList({ state }, [data, appealId]) {
  state.appealsList
    .find((item) => {
      return item.id === appealId;
    })
    .messages?.data?.push(data);
}
export function getPlaceName({ commit, getters }, data) {
  const arr = data.type === "city" ? getters?.citiesList : getters?.regionsList;

  return arr?.find((place) =>
    data.type === "city" ? place.id === data.id : place.region_id === data.id
  )?.title;
}
